import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER, Provider } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found.component';
import { ContentLayoutComponent } from './layouts/content-layout/content-layout.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { HeaderComponent } from './layouts/header/header.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CoreModule } from './core/core.module';
import { reducers, metaReducers } from './store';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './store/effects/app.effects';
import { ExitUrlService } from './services/exit-url.service';
import { SharedModule } from './shared/shared.module';
import { PageExitGuard } from './shared/page-exit/page-exit.guard';
import { ExternalUrlDirective } from './shared/page-exit/external-url.directive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserIdleModule } from 'angular-user-idle';
import { AppService } from './store/services/app.service';
import { NgxMaskModule } from 'ngx-mask';
import { LocalStorageService } from './services/local-storage.service';
import { CommonFunctions } from './shared/common/common-functions';
import { LocalStorageInit } from './shared/local-storage-init';
import { IConfig } from 'ngx-mask/lib/config';
import { StatusInterceptor } from './services/status-interceptor.service';
import { SessionStorageInit } from './shared/session-storage-init';
import { SessionStorageService } from './services/session-storage.service';
import { UserIdleSessionHandler } from './shared/user-idle-session-handler/user-idle-session-handler';
import { BrowserStorageHandler } from './shared/browser-storage-handler/browser-storage-handler';
import { PageEnterGuard } from './shared/page-enter/page-enter.guard';
import { FormComponentsModule } from './shared/form-components/form-components.module';
import { FormFunctions } from './shared/form-components/form-functions';
import { DatetimeFormatter } from './shared/common/formatters/datetime.formatter';
import { CurrencyFormatter } from './shared/common/formatters/currency.formatter';
import { PayrollFieldFunctions } from './shared/form-components/number-fields/payroll-fields/payroll-field.functions';
import { StartPageViewLogGuard } from './shared/start-page-view-log/start-page-view-log.guard';
import { ApplicationInsightsErrorHandler } from './core/appinsights-error-handler';
import { ContactInfoCommonFunctions } from './shared/form-components/contact-info-common/contact-info-common.function';
import { SECURITY_SETTINGS, AuthService, IdamAuthService, TokenInterceptor } from '@wsbc/ux-lib-security';
import { IdamAuthenticationGuard } from './shared/idam-auth-guard/idam-auth-guard';
import { initApp } from './app-init';
import { LandingPageGuard } from './shared/landing-page-guard/landing-page-guard';

import { ContactInfoCommonCoreFunctions } from './shared/form-components/contact-info-common-core/contact-info-common-core.function';
import { ShareholdersInfoCommonFunctions } from './modules/corporation/shareholders-info/shareholders-Info-common.functions';
import { ClassificationSearchViewCoreFunctions } from './shared/form-components/classification-view/classification-search-view-core/classification-search-view-core.functions';
import { ContactInfoCommonSoleProprietorFunctions } from './shared/form-components/contact-info-common-soleproprietor/contact-info-common-soleproprietor.function';
import { ExternalRedirectComponent } from './external-redirect.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

console.log('app.module environment.name=' + environment.name);

let authenticationProviders: Provider[] = [];
if (environment.name === 'default') {
  // replace to msalConfig.authenticationProviders when need to debug login related tasks
  authenticationProviders = environment.authenticationProviders; 
      
} else { 
  authenticationProviders = [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [AuthService, Store]
    },
    IdamAuthService,
    IdamAuthenticationGuard,
    {
      provide: AuthService,
      useExisting: IdamAuthService
    },
    {
      provide: SECURITY_SETTINGS,
      useValue: environment.security
    },
  ];
} 

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    ExternalRedirectComponent,
    ContentLayoutComponent,
    FooterComponent,
    HeaderComponent,
    ExternalUrlDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    FormComponentsModule,
    ReactiveFormsModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      },
      metaReducers
    }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
        maxAge: 30
      })
      : [],
    EffectsModule.forRoot([AppEffects]),
    AppRoutingModule,
    NgxMaskModule.forRoot(options),
    UserIdleModule.forRoot({idle: environment.allowedUserIdleTime, timeout: environment.timeoutDuration, ping: environment.pingPeriod}),
    environment.name === 'default' ? (environment.additionalModules) : []
  ],
  exports: [
    ContentLayoutComponent
  ],
  providers: [
    ExitUrlService,
    PageExitGuard,
    PageEnterGuard,
    StartPageViewLogGuard,
    LandingPageGuard,
    AppService,
    LocalStorageService,
    SessionStorageService,
    CommonFunctions,
    DatetimeFormatter,
    CurrencyFormatter,
    PayrollFieldFunctions,
    ContactInfoCommonFunctions,
    ContactInfoCommonCoreFunctions,
    ContactInfoCommonSoleProprietorFunctions,
    ShareholdersInfoCommonFunctions,
    ClassificationSearchViewCoreFunctions,
    FormFunctions,
    UserIdleSessionHandler,
    BrowserStorageHandler,
    LocalStorageInit,
    SessionStorageInit,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StatusInterceptor,
      multi: true
    },
    {
      provide: 'googleTagManagerId',
      useValue: environment.googleTagManagerId
    },
    {
      provide: 'canadaPostAddressCompleteId',
      useValue: environment.canadaPostAddressCompleteId
    },
    { 
      provide : ErrorHandler, 
      useClass : ApplicationInsightsErrorHandler
    },
    ...authenticationProviders,
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }