import { IEnvironment } from './environment.model';

export const environment : IEnvironment = {
    production: false,
    buildVersion: 'ASSESSMENTS_REG.WEB_6.0.0_20250125.002642.master',
    name: 'development',
    appInsights: {
      instrumentationKey: 'e0057b27-3c4f-4200-bc6a-4e9cd7fb22f0'
    },
    appInsightsStartSafe: {
      instrumentationKey: '33167fb1-3508-4529-b5e9-e1be4971765d'
    },
    allowedUserIdleTime: 1500,
    timeoutDuration: 300,
    pingPeriod: 120,
    idamSignupURL: 'https://idm.sy.worksafebc.com/iam/im/public/ui7/index.jsp?task.tag=signup',
    registrationApiUrl: 'https://asmtregistrationsapi.online.dv.worksafebc.com',    
    classificationPDFUrl: 'https://www.worksafebc.com/-/media/WorksafeBC/Classifications/',
    worksafebcbcUrl: 'https://www.worksafebc.com/',
    employerServiceCenterUrl: 'https://www.worksafebc.com/en/contact-us/departments-and-services/insurance-assessments',
    registrationPopUpSurveyLink: 'https://worksafebc.qualtrics.com/jfe/form/SV_d4FYu3eSfEGXomW',
    contractorsAndSubcontractorsUrl: 'https://www.worksafebc.com/en/insurance/need-coverage/who-needs-coverage/contractors-subcontractors',
    oneStopLink: 'https://test.onestop.gov.bc.ca/partner.do?method=submit&partner=WCB',
    protectionPrivacyOfficeUrl: 'https://www.worksafebc.com/en/contact-us/departments-and-services/fipp-office',
    optionalCoverageUrl: 'https://www.worksafebc.com/en/insurance/need-coverage/optional-coverage',
    personalOptionalProtectionFormUrl: 'https://www.worksafebc.com/en/resources/insurance/forms/personal-optional-protection-form-1801?lang=en',
    showRegistrationPopUpSurveyFlag: true,
    registrationPopUpSurveyPercentage: '100',
    isStartSafeEnabled: false,
    googleTagManagerId: 'GTM-MBCNMVJ',
    canadaPostAddressCompleteId: "BK65-MG25-JJ49-WD46",
    canadaPostCssUrl: `https://ws1.postescanada-canadapost.ca/css/addresscomplete-2.30.min.css`,
    canadaPostScriptSrc: `https://ws1.postescanada-canadapost.ca/js/addresscomplete-2.30.js`,
    journeyBarEnabled: {
      partnership: true,
      proprietor: true,
      homeowner: true,
      incorporated: true,
      corporation: true,
      soleproprietor: true
    },    
    security: {
      host: 'asmtregistrations.online.dv.worksafebc.com',
      stsAuthority: 'https://auth.sy.worksafebc.com/am/oauth2/alpha',
      clientId: '2-98-IREG-DEV',
      clientRoot: 'https://asmtregistrations.online.dv.worksafebc.com',
      errorUrl: 'https://op.online.dv.worksafebc.com/NotAuthorized',
      scopes: ["wsbcprofile"]     
    },
    addGuards: (routes) => routes,
    additionalModules: [],
    authenticationProviders: []
  };
